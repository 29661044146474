import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import { concat } from "lodash";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUsersByRoleNameAction } from "../../../../../authentication/data-access/src/lib/authentication.slice";
import { JOB_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import {
	DEFAULT_PAGE_SIZE,
	DEFAULT_TAKE_SIZE,
} from "../../../../../common/models/src/lib/constants/grid.constants";
import {
	JOBS_PAGE_SIZE,
	JOB_STATUSES,
	JOB_STATUS_MAPPINGS,
} from "../../../../../common/models/src/lib/constants/job.constants";
import {
	ADMIN_ROLE,
	OPERATOR_ROLE,
} from "../../../../../common/models/src/lib/constants/user.constants";
import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { CommandCellType } from "../../../../../common/models/src/lib/enums/command-cell.enum";
import { FilterItemType } from "../../../../../common/models/src/lib/enums/filter-item-type.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../common/models/src/lib/interfaces/base.interface";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../../common/models/src/lib/interfaces/filter.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumFilters } from "../../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumLoader } from "../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { buildFilterQueryString } from "../../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import { fetchConstraintsAction } from "../../../../../constraints/data-access/src/lib/constraints.slice";
import { fetchCustomersAction } from "../../../../../customers/data-access/src/lib/customers.slice";
import { fetchJobTypesAction } from "../../../../data-access/src/lib/job-types.slice";
import {
	clearJobsAction,
	fetchJobByIdAction,
	fetchJobsAction,
} from "../../../../data-access/src/lib/jobs.slice";
import { DuplicateJobForm } from "../components/duplicate-job-form/duplicate-job-form.component";
import { JobForm } from "../components/job-form/job-form.component";
import {
	canCancel,
	canDelete,
	canDuplicate,
	canForceComplete,
	canGenerateFileFront,
	canPause,
	canResume,
	canSendToInvoice,
} from "../helpers/job-option.helpers";
import { useJobActionSelected } from "../hooks/use-job-action-selected.hook";
import styles from "./jobs-feature.module.scss";

interface JobFilterState {
	statusFilter: IFilterItem[];
	assignedToFilter: IFilterItem[];
	jobTypeFilter: IFilterItem[];
	startDateFilter: IDateFilter[];
	endDateFilter: IDateFilter[];
}

const initialFilterState: JobFilterState = {
	statusFilter: [],
	assignedToFilter: [],
	jobTypeFilter: [],
	startDateFilter: [],
	endDateFilter: [],
};

const initialSortState = "uniqueId-asc";

export const JobsFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const navigate = useNavigate();
	const handleSelectedAction = useJobActionSelected();

	const {
		jobs: { data, job, total, loading },
		authentication: { users },
		jobTypes,
	} = useAppSelector((state) => ({
		jobs: state.jobs,
		authentication: state.authentication,
		jobTypes: state.jobTypes.data,
	}));

	const [sort, setSort] = useState<string>("");
	const [filters, setFilters] = useState<string>("");
	const [page, setPage] = useState<Page>({ skip: 0, take: JOBS_PAGE_SIZE });
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
	const [selectedJobUniqueId, setSelectedJobUniqueId] = useState<
		string | undefined
	>(undefined);
	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);
	const [showModal, setShowModal] = useState<{
		create: boolean;
		duplicate: boolean;
		actions: boolean;
	}>({
		create: false,
		duplicate: false,
		actions: false,
	});
	const [modalSize, setModalSize] = useState<ModalSize>(ModalSize.Large);

	const [jobFilters, setJobFilters] = useOldLocalStorage<JobFilterState>(
		"jobFilterState",
		initialFilterState,
	);
	useOldLocalStorage("jobSortState", initialSortState);

	const renderedJobDetails = useMemo(() => {
		if (job) {
			return {
				...job,
				startDate: new Date(job.startDate),
				endDate: new Date(job.endDate),
			};
		}
	}, [job]);

	const renderedJobs = useMemo(() => {
		return data.map((job) => {
			const startDate = new Date(job.startDate).toLocaleString();
			const endDate = new Date(job.endDate).toLocaleString();

			return {
				...job,
				startDate,
				endDate,
			};
		});
	}, [data]);

	const renderedAssignedToList = useMemo(
		() =>
			users.map((user) => {
				return {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
			}),
		[users],
	) as IBase[];

	const renderedJobTypesList = useMemo(
		() =>
			jobTypes.map((jobType) => {
				return {
					id: jobType.id,
					name: jobType.name,
				};
			}),
		[jobTypes],
	) as IBase[];

	const clearFilters = () => {
		setJobFilters(initialFilterState);
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < data.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (data[i]?.id === -1) {
				if (loading.grid) {
					return;
				}

				const page = Math.ceil(skip / JOBS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchJobsAction,
					payload: { page, pageSize: JOBS_PAGE_SIZE, sort, filters },
					errorMessage: "Could not fetch jobs.",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/**
		 * Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearJobsAction());
		dispatchWithNotifications({
			action: fetchJobsAction,
			payload: { page: 1, pageSize: JOBS_PAGE_SIZE, sort, filters },
			errorMessage: "Could not fetch jobs.",
		});
	};

	const openCreateModal = () => {
		setShowModal({ ...showModal, create: true });
	};

	const closeModal = () => {
		setShowModal({ create: false, duplicate: false, actions: false });
		setSelectedJobId(null);
		setSelectedAction(null);
	};

	const handleMoreOptions = {
		canDelete,
		canCancel,
		canPause,
		canDuplicate,
		canForceComplete,
		canGenerateFileFront,
		canSendToInvoice,
		canResume,
	};

	const handleMoreOptionsSelected = (event: MenuSelectEvent, id: number) => {
		const value = event.item.text as keyof typeof CelerumActions;

		if (!value) {
			return;
		}

		const selectedJob = data.find((job) => job.id === id);

		setSelectedJobId(id);
		setSelectedJobUniqueId(selectedJob?.uniqueId || undefined);

		if (CelerumActions[value] === CelerumActions.Duplicate) {
			dispatchWithNotifications({
				action: fetchJobByIdAction,
				payload: id,
				errorMessage: "Could not fetch job.",
			});
			setShowModal({ ...showModal, duplicate: true });
		} else {
			setSelectedAction(CelerumActions[value]);
			setShowModal({ ...showModal, actions: true });
		}
	};

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchJobTypesAction,
			errorMessage: "Could not fetch job types.",
		});
		dispatchWithNotifications({
			action: fetchCustomersAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch customers.",
		});
		dispatchWithNotifications({
			action: fetchUsersByRoleNameAction,
			payload: [OPERATOR_ROLE, ADMIN_ROLE],
			errorMessage: "Could not fetch users!",
		});
		dispatchWithNotifications({
			action: fetchConstraintsAction,
			payload: {},
			errorMessage: "Could not fetch constraints.",
		});

		return () => {
			dispatch(clearJobsAction());
		};
	}, [dispatch, dispatchWithNotifications]);

	useEffect(() => {
		const typesFilterLists = [];

		jobFilters.statusFilter.length &&
			typesFilterLists.push(jobFilters.statusFilter);
		jobFilters.jobTypeFilter.length &&
			typesFilterLists.push(jobFilters.jobTypeFilter);
		jobFilters.assignedToFilter.length &&
			typesFilterLists.push(jobFilters.assignedToFilter);

		const combinedDateFilters = concat(
			jobFilters.startDateFilter,
			jobFilters.endDateFilter,
		);

		const filters = buildFilterQueryString(
			searchFilter,
			[
				"uniqueId",
				"purchaseOrderNumber",
				"customerName",
				"startLocationName",
				"endLocationName",
				"assignedToName",
			],
			combinedDateFilters,
			typesFilterLists,
		);

		setFilters(filters);

		if (searchFilter || combinedDateFilters.length || typesFilterLists.length) {
			dispatchWithNotifications({
				action: fetchJobsAction,
				payload: { page: 1, pageSize: JOBS_PAGE_SIZE, filters },
				errorMessage: "Could not fetch jobs.",
			});
		} else {
			dispatchWithNotifications({
				action: fetchJobsAction,
				payload: { page: 1, pageSize: JOBS_PAGE_SIZE },
				errorMessage: "Could not fetch jobs.",
			});
		}
		setPage({ skip: 0, take: DEFAULT_TAKE_SIZE });
	}, [dispatchWithNotifications, jobFilters, searchFilter]);

	useEffect(() => {
		if (loading.grid) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading.grid]);

	return (
		<>
			<CelerumGridHeader
				title="Jobs"
				numberOfItems={total}
				addButtonName="Add Job"
				addButtonDisabled={loading.grid}
				handleOpenAddModal={openCreateModal}
			>
				<CelerumFilters
					setFilters={setJobFilters}
					setSearchFilter={setSearchFilter}
					initialValues={{
						statusFilter: jobFilters.statusFilter,
						jobTypeFilter: jobFilters.jobTypeFilter,
						assignedToFilter: jobFilters.assignedToFilter,
						startDateFilter: jobFilters.startDateFilter,
						endDateFilter: jobFilters.endDateFilter,
					}}
					filters={{
						statusFilter: {
							name: "Status",
							column: "status",
							values: JOB_STATUSES,
							type: FilterItemType.DROPDOWN,
						},
						jobTypeFilter: {
							name: "Job Type",
							column: "jobTypeId",
							values: renderedJobTypesList,
							type: FilterItemType.DROPDOWN,
						},
						assignedToFilter: {
							name: "Assigned To",
							column: "assignedTo",
							values: renderedAssignedToList,
							type: FilterItemType.DROPDOWN,
						},
						startDateFilter: {
							name: "Start Date",
							column: "startDate",
							type: FilterItemType.DATERANGE,
						},
						endDateFilter: {
							name: "End Date",
							column: "endDate",
							type: FilterItemType.DATERANGE,
						},
					}}
					clearFilters={clearFilters}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				page={page}
				setPage={setPage}
				columns={JOB_GRID_COLUMNS}
				data={renderedJobs}
				total={total}
				loading={loading.grid}
				requestDataIfNeeded={requestDataIfNeeded}
				requestSortedData={requestSortedData}
				commandCellType={CommandCellType.NavigateViewMore}
				handleNavigate={(id) =>
					navigate(`${id}`, { state: { from: window.location.pathname } })
				}
				handleMoreOptions={handleMoreOptions}
				handleActionSelected={handleMoreOptionsSelected}
				statusMappings={JOB_STATUS_MAPPINGS}
			/>
			<CelerumModal
				title="Job Details"
				width={modalSize}
				toggleDialog={closeModal}
				visible={showModal.create}
			>
				<JobForm onClose={closeModal} setModalSize={setModalSize} />
			</CelerumModal>
			{renderedJobDetails && (
				<CelerumModal
					title="Job Details"
					width={modalSize}
					toggleDialog={closeModal}
					visible={showModal.duplicate}
				>
					{loading.job ? (
						<div className={styles.verticalWrapper}>
							<CelerumLoader visible />
						</div>
					) : (
						<DuplicateJobForm
							formState={renderedJobDetails}
							onClose={closeModal}
							setModalSize={setModalSize}
						/>
					)}
				</CelerumModal>
			)}
			<CelerumConfirmModal
				title={
					selectedAction &&
					`Are you sure you want to ${CelerumActions[selectedAction]
						?.toString()
						.toLowerCase()} this job?`
				}
				entityName="job"
				entityProperty="with ID"
				entityValue={selectedJobUniqueId}
				isOpen={showModal.actions}
				subtitle={
					selectedAction === CelerumActions.Delete
						? "The job cannot be recovered."
						: "This action can be reverted afterwards."
				}
				type={
					selectedAction === CelerumActions.Delete
						? ModalType.Delete
						: ModalType.Warning
				}
				handleSubmit={() => {
					handleSelectedAction(
						selectedAction,
						selectedJobId,
						selectedJobUniqueId,
					);
					closeModal();
				}}
				handleClose={closeModal}
			/>
		</>
	);
};
