import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import { concat } from "lodash";
import NProgress from "nprogress";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INVOICE_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import { DEFAULT_TAKE_SIZE } from "../../../../../common/models/src/lib/constants/grid.constants";
import {
	INVOICES_PAGE_SIZE,
	INVOICE_STATUSES,
} from "../../../../../common/models/src/lib/constants/invoice.constants";
import { JOB_STATUS_MAPPINGS } from "../../../../../common/models/src/lib/constants/job.constants";
import { NOT_AVAILABLE } from "../../../../../common/models/src/lib/constants/messages.constants";
import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { CommandCellType } from "../../../../../common/models/src/lib/enums/command-cell.enum";
import { FilterItemType } from "../../../../../common/models/src/lib/enums/filter-item-type.enum";
import { InvoiceTypeMethod } from "../../../../../common/models/src/lib/enums/invoice.enum";
import { JobStatus } from "../../../../../common/models/src/lib/enums/job.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../common/models/src/lib/interfaces/base.interface";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../../common/models/src/lib/interfaces/filter.interface";
import type { IInvoiceableJob } from "../../../../../common/models/src/lib/interfaces/invoice.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumFilters } from "../../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumLoader } from "../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import {
	getCurrencySymbol,
	getFormattedValue,
} from "../../../../../common/utils/src/lib/helpers/currency.helpers";
import { buildFilterQueryString } from "../../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import {
	clearInvoiceableJobsAction,
	createInvoiceAction,
	fetchInvoiceableJobsAction,
} from "../../../../data-access/src/lib/invoices.slice";
import { GeneratedInvoiceModalContent } from "../components/generated-invoice-modal-content/generated-invoice-modal-content.component";
import { InvoiceCustomersModalContent } from "../components/selected-invoices-modal-content/selected-invoices-modal-content.component";
import {
	canEditInvoice,
	canMarkAsSent,
	canPreviewInvoice,
	canReInvoice,
	canSendToInvoicingCheck,
} from "../helpers/invoice-option.helpers";
import { useInvoiceableJobActionSelected } from "../hooks/use-invoiceable-job-action-selected.hook";
import styles from "./invoices-feature.module.scss";

interface InvoicesFilterState {
	statusFilter: IFilterItem[];
	assignedToFilter: IFilterItem[];
	startDateFilter: IDateFilter[];
	endDateFilter: IDateFilter[];
}

const initialFilterState: InvoicesFilterState = {
	statusFilter: [],
	assignedToFilter: [],
	startDateFilter: [],
	endDateFilter: [],
};

export type RenderedInvoices = Omit<
	IInvoiceableJob,
	"grossSum" | "price" | "cost"
> & {
	startDate: string;
	endDate: string;
	price: string;
	cost: string;
	grossSum: string;
	grossSumNumber: number;
	vatRate: string;
	invoicePreference: string;
	canBeChecked: boolean;
};

export const InvoicesFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const navigate = useNavigate();
	const handleSelectedAction = useInvoiceableJobActionSelected();

	const {
		invoices,
		total,
		generatedInvoices,
		loading,
		authentication: { users },
	} = useAppSelector((state) => ({
		invoices: state.invoices.data,
		total: state.invoices.total,
		loading: state.invoices.loading,
		authentication: state.authentication,
		generatedInvoices: state.invoices.generatedInvoices,
	}));

	const [page, setPage] = useState<Page>({ skip: 0, take: INVOICES_PAGE_SIZE });
	const [sort, setSort] = useState<string>("");
	const [filters, setFilters] = useState<string>("");
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [selectedJobIds, setSelectedJobIds] = useState<Set<number>>(
		new Set<number>(),
	);
	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);
	const [selectedInvoiceableJobId, setSelectedInvoiceableJobId] =
		useState<CelerumActions | null>(null);
	const [selectedInvoiceableJobStatus, setSelectedInvoiceableJobStatus] =
		useState<JobStatus | undefined>(undefined);
	const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
	const [showActionModal, setShowActionModal] = useState<boolean>(false);
	const [postingDate, setPostingDate] = useState<Date>(new Date());

	const [invoiceFilters, setInvoiceFilters] =
		useOldLocalStorage<InvoicesFilterState>(
			"invoicesFilterState",
			initialFilterState,
		);

	const renderedInvoices: RenderedInvoices[] = useMemo(() => {
		return invoices.map((invoice) => {
			const canBeChecked = !(
				invoice.status === JobStatus.CHECKED ||
				invoice.status === JobStatus.CHECKED_AGAIN
			);

			const startDate = new Date(invoice.startDate).toLocaleString();
			const endDate = new Date(invoice.endDate).toLocaleString();
			const price = `${getCurrencySymbol(invoice.customerCurrencyCode)} ${(
				Math.round((invoice.price ?? 0) * 100) / 100
			).toFixed(2)}`;
			const cost = `${getCurrencySymbol(invoice.customerCurrencyCode)} ${(
				Math.round((invoice.cost ?? 0) * 100) / 100
			).toFixed(2)}`;
			const grossSum = `${getCurrencySymbol(invoice.customerCurrencyCode)} ${(
				Math.round((invoice.grossSum ?? 0) * 100) / 100
			).toFixed(2)}`;

			return {
				...invoice,
				canBeChecked,
				startDate,
				endDate,
				price,
				cost,
				grossSum,
				grossSumNumber: invoice.grossSum,
				vatRate:
					invoice.vatRate && invoice.vatRate !== -1
						? `${getFormattedValue(invoice.vatRate as number)}%`
						: NOT_AVAILABLE,

				invoicePreference:
					InvoiceTypeMethod[invoice.customerInvoiceType] ?? NOT_AVAILABLE,
			};
		});
	}, [invoices]);

	const renderedSelectedInvoices = useMemo(() => {
		const invoicesList = renderedInvoices.filter((invoice) => {
			return selectedJobIds?.has(invoice.id);
		});

		const resultMap: { [key: number]: RenderedInvoices } = {};

		for (const item of invoicesList) {
			const { customerId, uniqueId } = item;

			if (customerId) {
				if (!resultMap[customerId]) {
					resultMap[customerId] = {
						...item,
						uniqueIdList: [],
					};
				}

				resultMap[customerId]?.uniqueIdList.push({
					uniqueId: uniqueId,
					cost: item.grossSumNumber,
					id: item.id,
				});
			}
		}

		const resultArray = Object.values(resultMap);

		return resultArray;
	}, [renderedInvoices, selectedJobIds]);

	const renderedAssignedToList = useMemo(
		() =>
			users.map((user) => {
				return {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
			}),
		[users],
	) as IBase[];

	const isInvoiceCreationActionSuccessful = useMemo(() => {
		return generatedInvoices.every(
			(item) => !item.invoices.every((invoice) => invoice.isFailed),
		);
	}, [generatedInvoices]);

	const handleInvoiceFilters = useCallback(() => {
		const typesFilterLists: IFilterItem[][] = [];

		invoiceFilters.statusFilter.length &&
			typesFilterLists.push(invoiceFilters.statusFilter);
		invoiceFilters.assignedToFilter.length &&
			typesFilterLists.push(invoiceFilters.assignedToFilter);
		typesFilterLists.push([
			{ id: "status", value: [JobStatus.READY_FOR_INVOICE], neq: true },
		]);

		const combinedDateFilters = concat(
			invoiceFilters.startDateFilter,
			invoiceFilters.endDateFilter,
		);

		const filters = buildFilterQueryString(
			searchFilter,
			[
				"customerName",
				"invoiceNumber",
				"uniqueId",
				"assignedToName",
				"customerCurrencyCode",
				"purchaseOrderNumber",
			],
			combinedDateFilters,
			typesFilterLists,
		);

		setFilters(filters);

		const payload = {
			page: 1,
			pageSize: INVOICES_PAGE_SIZE,
			...(searchFilter || combinedDateFilters.length || typesFilterLists.length
				? { filters }
				: {}),
		};

		dispatchWithNotifications({
			action: fetchInvoiceableJobsAction,
			payload,
			errorMessage: "Could not fetch invoiceable jobs.",
		});

		setPage({ skip: 0, take: DEFAULT_TAKE_SIZE });
	}, [dispatchWithNotifications, invoiceFilters, searchFilter]);

	const handleGenerateInvoice = async () => {
		const jobIds = renderedSelectedInvoices.flatMap(
			(item) => item?.uniqueIdList?.map((element) => element.id) ?? [],
		);

		const payload = { jobIds, date: postingDate.toISOString() };

		const actionResult = await dispatchWithNotifications({
			action: createInvoiceAction,
			payload,
		});

		if (createInvoiceAction.fulfilled.match(actionResult)) {
			handleInvoiceFilters();
			setShowSuccessModal(true);
		}
	};

	const clearFilters = () => {
		setInvoiceFilters(initialFilterState);
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < invoices.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (invoices[i]?.id === -1) {
				if (loading.invoices) {
					return;
				}

				const page = Math.ceil(skip / INVOICES_PAGE_SIZE) + 1;

				dispatchWithNotifications({
					action: fetchInvoiceableJobsAction,
					payload: { page, pageSize: INVOICES_PAGE_SIZE, sort, filters },
					errorMessage: "Could not fetch invoiceable jobs.",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/**
		 * Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearInvoiceableJobsAction());
		dispatchWithNotifications({
			action: fetchInvoiceableJobsAction,
			payload: { page: 1, pageSize: INVOICES_PAGE_SIZE, sort, filters },
			errorMessage: "Could not fetch invoiceable jobs.",
		});
	};

	const closeModal = () => {
		setShowActionModal(false);
		setSelectedInvoiceableJobId(null);
		setSelectedAction(null);
	};

	const handleMoreOptions = {
		canSendToInvoicingCheck,
		canPreviewInvoice,
		canMarkAsSent,
		canReInvoice,
		canEditInvoice,
	};

	const handleMoreOptionsSelected = (event: MenuSelectEvent, id: number) => {
		const value = event.item.text as keyof typeof CelerumActions;

		if (!value) {
			return;
		}

		const status = renderedInvoices.find(
			(invoice) => invoice.id === id,
		)?.status;

		setSelectedInvoiceableJobStatus(status);
		setSelectedInvoiceableJobId(id);
		setSelectedAction(CelerumActions[value]);
		setShowActionModal(true);
	};

	useEffect(() => {
		handleInvoiceFilters();
	}, [handleInvoiceFilters]);

	useEffect(() => {
		if (loading.invoices) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading.invoices]);

	return (
		<>
			<CelerumGridHeader
				title="Invoices"
				numberOfItems={total}
				addButtonName="Generate Invoice"
				addButtonDisabled={!renderedSelectedInvoices.length}
				handleOpenAddModal={() => setOpenInvoiceModal(true)}
			>
				<CelerumFilters
					setFilters={setInvoiceFilters}
					setSearchFilter={setSearchFilter}
					clearFilters={clearFilters}
					initialValues={{
						statusFilter: invoiceFilters.statusFilter,
						assignedToFilter: invoiceFilters.assignedToFilter,
						startDateFilter: invoiceFilters.startDateFilter,
						endDateFilter: invoiceFilters.endDateFilter,
					}}
					filters={{
						statusFilter: {
							name: "Status",
							column: "status",
							values: INVOICE_STATUSES.filter(
								(status) => status.id !== JobStatus.READY_FOR_INVOICE,
							),
							type: FilterItemType.DROPDOWN,
						},
						assignedToFilter: {
							name: "Assigned To",
							column: "assignedTo",
							values: renderedAssignedToList,
							type: FilterItemType.DROPDOWN,
						},
						startDateFilter: {
							name: "Start Date",
							column: "startDate",
							type: FilterItemType.DATERANGE,
						},
						endDateFilter: {
							name: "End Date",
							column: "endDate",
							type: FilterItemType.DATERANGE,
						},
					}}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				page={page}
				setPage={setPage}
				columns={INVOICE_GRID_COLUMNS}
				data={renderedInvoices}
				total={total}
				requestDataIfNeeded={requestDataIfNeeded}
				requestSortedData={requestSortedData}
				handleMoreOptions={handleMoreOptions}
				handleActionSelected={handleMoreOptionsSelected}
				loading={loading.invoices}
				commandCellType={CommandCellType.NavigateViewMore}
				handleNavigate={(id) =>
					navigate(`${id}`, { state: { from: window.location.pathname } })
				}
				selectedItemsIds={selectedJobIds}
				setSelectedItemsIds={setSelectedJobIds}
				statusMappings={JOB_STATUS_MAPPINGS}
			/>
			<CelerumConfirmModal
				type={ModalType.Warning}
				title="Do you want to proceed?"
				subtitle="Are you sure you want to generate invoices for the following customers?"
				size={ModalSize.Medium}
				isOpen={openInvoiceModal}
				handleClose={() => setOpenInvoiceModal(false)}
				handleSubmit={handleGenerateInvoice}
			>
				<InvoiceCustomersModalContent
					selectedInvoices={renderedSelectedInvoices}
					setPostingDate={setPostingDate}
					postingDate={postingDate}
				/>
			</CelerumConfirmModal>
			<CelerumModal
				width={ModalSize.Small}
				visible={loading.areInvoicesGenerated}
				height={325}
			>
				<div className={styles.loader}>
					<CelerumLoader visible />
				</div>
			</CelerumModal>
			<CelerumConfirmModal
				title={
					selectedAction &&
					`Are you sure you want to ${CelerumActions[selectedAction]
						?.toString()
						.toLowerCase()} this invoiceable job?`
				}
				itemName={`invoiceable job with id ${selectedInvoiceableJobId}`}
				isOpen={showActionModal}
				type={ModalType.Warning}
				handleSubmit={async () => {
					await handleSelectedAction(
						selectedAction,
						selectedInvoiceableJobId,
						selectedInvoiceableJobStatus,
					);
					closeModal();
					handleInvoiceFilters();
				}}
				handleClose={closeModal}
			/>
			<CelerumConfirmModal
				type={
					isInvoiceCreationActionSuccessful
						? ModalType.Success
						: ModalType.Error
				}
				title={
					isInvoiceCreationActionSuccessful
						? "Invoices successfully generated"
						: "Some invoices not generated"
				}
				size={ModalSize.Medium}
				isOpen={showSuccessModal}
				handleClose={() => {
					setShowSuccessModal(false);
					setSelectedJobIds(new Set<number>());
				}}
				handleSubmit={handleGenerateInvoice}
				showSubmitButton={false}
				cancelButtonName="Cancel"
			>
				<GeneratedInvoiceModalContent
					invoices={generatedInvoices}
					setPostingDate={setPostingDate}
					postingDate={postingDate}
				/>
			</CelerumConfirmModal>
		</>
	);
};
