import {
	Field,
	Form,
	FormElement,
	type FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addAttachmentsAction } from "../../../../../../attachments/data-access/src/lib/attachments.slice";
import { AttachmentUsage } from "../../../../../../common/models/src/lib/enums/attachment.enum";
import { CustomerStatus } from "../../../../../../common/models/src/lib/enums/customer.enum";
import { ModalSize } from "../../../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../../common/models/src/lib/interfaces/base.interface";
import type { ICurrency } from "../../../../../../common/models/src/lib/interfaces/currency.interface";
import type { IJobDetails } from "../../../../../../common/models/src/lib/interfaces/job.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumAddLocationLabel } from "../../../../../../common/ui/src/lib/components/celerum-add-location-label/celerum-add-location-label.component";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumFormDateTimePicker,
	CelerumFormDropDownList,
	CelerumFormInput,
	CelerumFormInputWithIcon,
	CelerumFormMultiSelect,
	CelerumFormTextArea,
	CelerumFormUploadInput,
	CelerumSuggestionsInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { SuffixComponent } from "../../../../../../common/ui/src/lib/components/celerum-suffix/celerum-suffix.component";
import { getCurrencySymbol } from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import { toISOStringWithoutTimezoneOffset } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { suggestionsSearchChange } from "../../../../../../common/utils/src/lib/helpers/suggestions-search-change.helpers";
import { useInputChange } from "../../../../../../common/utils/src/lib/hooks/use-input-change.hooks";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import { fetchCustomersAction } from "../../../../../../customers/data-access/src/lib/customers.slice";
import { fetchLocationsAction } from "../../../../../../locations/data-access/src/lib/locations.slice";
import { LocationForm } from "../../../../../../locations/feature/src/lib/components/location-form/location-form.component";
import {
	attachDocumentsToJobAction,
	createJobAction,
} from "../../../../../data-access/src/lib/jobs.slice";
import styles from "./job-form.module.scss";

interface JobFormProps {
	onClose: () => void;
	setModalSize: Dispatch<SetStateAction<ModalSize>>;
}

export const JobForm = ({ onClose, setModalSize }: JobFormProps) => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const navigate = useNavigate();
	const handleInputChange = useInputChange();

	const {
		constraints,
		currentBusinessUnit,
		customers,
		jobTypes,
		loading,
		loadingCustomers,
		users,
		locations,
	} = useAppSelector((state) => ({
		customers: state.customers.data,
		loadingCustomers: state.customers.loading,
		jobTypes: state.jobTypes.data,
		constraints: state.constraints.data,
		loading: state.jobs.loading.grid,
		users: state.authentication.users,
		currentBusinessUnit: state.authentication.currentBusinessUnit,
		locations: state.locations.data,
	}));

	const [startDate, setStartDate] = useState(new Date());
	const [showLocationForm, setShowLocationForm] = useState<boolean>(false);
	const [currency, setCurrency] = useState<ICurrency>();
	const [formData, setFormData] = useState<IJobDetails | undefined>(
		{} as IJobDetails,
	);
	const [fieldToUpdate, setFieldToUpdate] = useState<string>();
	const [isFormModified, setIsFormModified] = useState<boolean>(false);

	const handleSearchCustomersChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name"],
		reduxAction: fetchCustomersAction,
		extraTypesLists: [[{ id: "status", value: [CustomerStatus.ACTIVE] }]],
	});

	const handleSearchLocationsChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name", "address"],
		reduxAction: fetchLocationsAction,
	});

	const handleSubmit = async (event: FormSubmitClickEvent) => {
		if (!event.isValid) {
			return;
		}

		const {
			files,
			startDate,
			endDate,
			purchaseOrderNumber,
			notes,
			price,
			startLocation,
			endLocation,
			customer,
			jobType,
			assignedToUser,
			goodsName,
			goodsQuantity,
			goodsWeight,
			goodsConstraints,
			containerNumber,
			containerPin,
			containerSealNumber,
		} = event.values;

		const formData = new FormData();
		if (files?.length) {
			for (const file of files) {
				formData.append("files", file.getRawFile?.() || new Blob());
			}
		}

		const requestObject = {
			startDate: toISOStringWithoutTimezoneOffset(startDate),
			endDate: toISOStringWithoutTimezoneOffset(endDate),
			purchaseOrderNumber: purchaseOrderNumber,
			notes: notes,
			price: price,
			startLocationId: startLocation.id,
			endLocationId: endLocation.id,
			customerId: customer?.id,
			jobTypeId: jobType?.id,
			assignedTo: assignedToUser?.id,
			businessUnitName: currentBusinessUnit?.name,
			goodsInfo: {
				name: goodsName,
				quantity: goodsQuantity,
				weight: goodsWeight,
				constraints: goodsConstraints
					? goodsConstraints.map((constraint: IBase) => constraint.id)
					: [],
			},
			containerNumber,
			containerPin,
			containerSealNumber,
		};

		const actionResult = await dispatchWithNotifications({
			action: createJobAction,
			payload: requestObject,
			errorMessage: "Could not create job.",
		});

		if (createJobAction.fulfilled.match(actionResult)) {
			if (files?.length > 0) {
				const actionResultAttachments = await dispatch(
					addAttachmentsAction({
						documents: formData,
						entity: AttachmentUsage.Job,
						entityId: actionResult.payload.id,
					}),
				);
				if (addAttachmentsAction.fulfilled.match(actionResultAttachments)) {
					dispatch(attachDocumentsToJobAction(actionResultAttachments.payload));
					onClose();
					navigate(`/jobs/${actionResult.payload.id}`, {
						state: { from: window.location.pathname },
					});
				}
			} else {
				onClose();
				navigate(`/jobs/${actionResult.payload.id}`, {
					state: { from: window.location.pathname },
				});
			}
		}
	};

	useEffect(() => {
		showLocationForm
			? setModalSize(ModalSize.Medium)
			: setModalSize(ModalSize.Large);
	}, [showLocationForm, setModalSize]);

	const renderJobForm = () => (
		<Form
			onSubmitClick={handleSubmit}
			initialValues={formData}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<div className={styles.formColumns}>
							<div>
								<Field
									id="jobType"
									dataItemKey="id"
									label="Job Type"
									textField="name"
									name="jobType"
									component={CelerumFormDropDownList}
									onChange={(event) => {
										handleInputChange(event, "jobType", setFormData);
									}}
									data={jobTypes}
									required
									validator={requiredValidator}
								/>
								<Field
									id="customer"
									dataItemKey="id"
									label="Customer"
									textField="name"
									name="customer"
									loading={loadingCustomers}
									component={CelerumSuggestionsInput}
									data={customers}
									handleSearchChange={handleSearchCustomersChange}
									required
									onChange={(event) => {
										setCurrency(event?.target?.value?.currency);
										handleInputChange(event, "customer", setFormData);
									}}
									validator={requiredValidator}
								/>
								<Field
									id="purchaseOrderNumber"
									name="purchaseOrderNumber"
									label="Purchase Order Number"
									component={CelerumFormInput}
									required
									validator={requiredValidator}
									onChange={(event) => {
										handleInputChange(
											event,
											"purchaseOrderNumber",
											setFormData,
										);
									}}
									maxLength={250}
									focused="true"
								/>

								<Field
									id="startLocation"
									dataItemKey="id"
									label="Start Location"
									textField="nameAndAddress"
									name="startLocation"
									component={CelerumSuggestionsInput}
									data={locations}
									handleSearchChange={handleSearchLocationsChange}
									onChange={(event) => {
										handleInputChange(event, "startLocation", setFormData);
									}}
									validator={requiredValidator}
									onFooterClick={() => {
										setShowLocationForm(true);
										setFieldToUpdate("startLocation");
									}}
									required
									footer={<CelerumAddLocationLabel />}
								/>
								<Field
									id="startDate"
									name="startDate"
									label="Start Date"
									component={CelerumFormDateTimePicker}
									validator={requiredValidator}
									required
									onChange={(event) => {
										setStartDate(event.target.value);
										handleInputChange(event, "startDate", setFormData);
									}}
								/>
								<Field
									id="files"
									name="files"
									label="Upload Documents"
									component={CelerumFormUploadInput}
									onChange={(event) => {
										setFormData((prev: IJobDetails | undefined) => {
											if (prev) {
												return { ...prev, files: event.value };
											}
										});
									}}
								/>
							</div>
							<div className={styles.formSecondColumn}>
								<Field
									id="endLocation"
									dataItemKey="id"
									label="End Location"
									textField="nameAndAddress"
									name="endLocation"
									data={locations}
									handleSearchChange={handleSearchLocationsChange}
									validator={requiredValidator}
									onChange={(event) => {
										handleInputChange(event, "endLocation", setFormData);
									}}
									onFooterClick={() => {
										setShowLocationForm(true);
										setFieldToUpdate("endLocation");
									}}
									component={CelerumSuggestionsInput}
									required
									footer={<CelerumAddLocationLabel />}
								/>
								<Field
									id="endDate"
									name="endDate"
									min={startDate}
									label="End Date"
									component={CelerumFormDateTimePicker}
									onChange={(event) => {
										handleInputChange(event, "endDate", setFormData);
									}}
									validator={requiredValidator}
									required
								/>
								<Field
									id="price"
									name="price"
									label="Price"
									component={CelerumFormInputWithIcon}
									onChange={(event) =>
										handleInputChange(event, "price", setFormData)
									}
									maxLength={250}
									placeholder="0.00"
									type="number"
								>
									<SuffixComponent
										value={
											currency?.code
												? getCurrencySymbol(currency.code)
												: getCurrencySymbol("GBP")
										}
									/>
								</Field>
								<Field
									id="assignedToUser"
									dataItemKey="id"
									label="Assigned To"
									textField="fullName"
									name="assignedToUser"
									component={CelerumFormDropDownList}
									onChange={(event) =>
										handleInputChange(event, "assignedToUser", setFormData)
									}
									data={users}
									required
									validator={requiredValidator}
								/>
								<Field
									id="notes"
									label="Notes"
									name="notes"
									maxLength={500}
									component={CelerumFormTextArea}
									onChange={(event) =>
										handleInputChange(event, "notes", setFormData)
									}
									type="text"
								/>
							</div>
							<div>
								<Field
									id="name"
									label="Goods Description"
									name="goodsName"
									maxLength={500}
									component={CelerumFormTextArea}
									onChange={(event) =>
										handleInputChange(event, "goodsName", setFormData)
									}
									type="text"
									required
									validator={requiredValidator}
								/>
								<Field
									id="quantity"
									name="goodsQuantity"
									label="Quantity"
									placeholder="(1-1000)"
									component={CelerumFormInput}
									onChange={(event) =>
										handleInputChange(event, "goodsQuantity", setFormData)
									}
									maxLength={250}
									type="number"
									required
									validator={requiredValidator}
								/>
								<Field
									id="weight"
									name="goodsWeight"
									label="Weight"
									component={CelerumFormInputWithIcon}
									onChange={(event) =>
										handleInputChange(event, "goodsWeight", setFormData)
									}
									maxLength={250}
									placeholder="0 Kg"
									type="number"
									required
									validator={requiredValidator}
								>
									<SuffixComponent value="Kg" />
								</Field>
								<Field
									id="goods.constraints"
									name="goodsConstraints"
									onChange={(event) =>
										handleInputChange(event, "goodsConstraints", setFormData)
									}
									label="Constraints"
									dataItemKey="id"
									textField="name"
									data={constraints}
									component={CelerumFormMultiSelect}
								/>
								{formData?.jobType?.isContainer && (
									<>
										<Field
											id="containerNumber"
											name="containerNumber"
											onChange={(event) =>
												handleInputChange(event, "containerNumber", setFormData)
											}
											label="Container Number"
											maxLength={250}
											component={CelerumFormInput}
											type="text"
										/>
										<Field
											id="containerPin"
											name="containerPin"
											onChange={(event) =>
												handleInputChange(event, "containerPin", setFormData)
											}
											label="Container Pin"
											maxLength={250}
											component={CelerumFormInput}
											type="text"
										/>
										<Field
											id="containerSealNumber"
											name="containerSealNumber"
											onChange={(event) =>
												handleInputChange(
													event,
													"containerSealNumber",
													setFormData,
												)
											}
											label="Container Seal Number"
											maxLength={250}
											component={CelerumFormInput}
											type="text"
										/>
									</>
								)}
							</div>
						</div>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={
							(!isFormModified && !formRenderProps.allowSubmit) || loading
						}
						title="Create Job"
					/>
				</FormElement>
			)}
		/>
	);

	const renderLocationForm = () => (
		<LocationForm<IJobDetails>
			onClose={() => {
				setShowLocationForm(false);
			}}
			addLocationFromAnotherForm
			fieldToSet={fieldToUpdate}
			setFormData={setFormData}
			setIsParentFormModified={setIsFormModified}
		/>
	);

	return !showLocationForm ? renderJobForm() : renderLocationForm();
};
