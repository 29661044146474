import type { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
	Field,
	type FieldValidatorType,
	type FormRenderProps,
} from "@progress/kendo-react-form";
import {
	type Dispatch,
	type SetStateAction,
	useEffect,
	useMemo,
	useState,
} from "react";
import { jobApi } from "../../../../../../../../../new/helpers";
import { BUSINESS_UNITS_FEATURE_KEY } from "../../../../../../../../common/models/src/lib/constants/business-unit.constants";
import { DRIVERS_FEATURE_KEY } from "../../../../../../../../common/models/src/lib/constants/driver.constants";
import { DEFAULT_PAGE_SIZE } from "../../../../../../../../common/models/src/lib/constants/grid.constants";
import { SUPPLIER_INVOICES } from "../../../../../../../../common/models/src/lib/constants/invoice.constants";
import type { ParentType } from "../../../../../../../../common/models/src/lib/enums/parent-type.enum";
import type { IBase } from "../../../../../../../../common/models/src/lib/interfaces/base.interface";
import type { IDriver } from "../../../../../../../../common/models/src/lib/interfaces/driver.interface";
import type { IClearCustomsLeg } from "../../../../../../../../common/models/src/lib/interfaces/leg.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../../../common/stores/src/lib/utils";
import {
	CelerumFormDatePicker,
	CelerumFormDateTimePicker,
	CelerumFormDropDownList,
	CelerumFormInput,
	CelerumFormMultiSelect,
	CelerumFormTextArea,
	CelerumFormUploadInput,
	CelerumSuggestionsInput,
} from "../../../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { suggestionsSearchChange } from "../../../../../../../../common/utils/src/lib/helpers/suggestions-search-change.helpers";
import { useInputChange } from "../../../../../../../../common/utils/src/lib/hooks/use-input-change.hooks";
import {
	noNegativeValidator,
	requiredValidator,
} from "../../../../../../../../common/utils/src/lib/validators/validators";
import { fetchDriversAction } from "../../../../../../../../drivers/data-access/src/lib/drivers.slice";
import { fetchSubcontractorsAction } from "../../../../../../../../subcontractors/data-access/src/lib/subcontractors.slice";
import { fetchTrailersAction } from "../../../../../../../../trailers/data-access/src/lib/trailers.slice";
import { fetchTrucksAction } from "../../../../../../../../trucks/data-access/src/lib/trucks.slice";
import styles from "../../leg-form.module.scss";

interface ClearCustomsFieldsProps {
	goods: IBase[];
	formData: IClearCustomsLeg | undefined;
	selectedChauffeur: string;
	updateMode: boolean;
	formRenderProps: FormRenderProps;
	setGoodsIds: Dispatch<SetStateAction<number[]>>;
	parentType: ParentType;
	setSelectedChauffeur: Dispatch<SetStateAction<string>>;
	setFormData: Dispatch<SetStateAction<IClearCustomsLeg | undefined>>;
	setFieldToUpdate: Dispatch<SetStateAction<string | undefined>>;
}

export const ClearCustomsFields = ({
	goods,
	formData,
	selectedChauffeur,
	updateMode,
	setGoodsIds,
	setSelectedChauffeur,
	setFormData,
	formRenderProps,
}: ClearCustomsFieldsProps) => {
	const handleInputChange = useInputChange();
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const [cost, setCost] = useState<number | undefined>(undefined);

	const costValidator: FieldValidatorType | FieldValidatorType[] | undefined =
		cost !== undefined && cost < 0 ? noNegativeValidator : undefined;

	const currencyValidator:
		| FieldValidatorType
		| FieldValidatorType[]
		| undefined =
		cost !== undefined && cost >= 0 ? requiredValidator : undefined;

	const {
		trucks,
		trailers,
		currencies,
		drivers,
		subcontractors,
		businessUnits,
		currentBusinessUnit,
	} = useAppSelector((state) => ({
		trucks: state.trucks.data,
		trailers: state.trailers.data,
		currencies: state.currencies.data,
		drivers: state.drivers.data,
		subcontractors: state.subcontractors.data,
		businessUnits: state.businessUnits.data,
		currentBusinessUnit: state.authentication.currentBusinessUnit,
	}));

	const renderedTrucks = useMemo(
		() =>
			trucks.map((item) => ({
				id: item.id,
				name: `${item.name} - ${item.registration} ${
					item.subcontractor?.name ? `(${item.subcontractor.name})` : ""
				}`,
			})),
		[trucks],
	);

	const renderedTrailers = useMemo(
		() =>
			trailers.map((item) => ({
				id: item.id,
				name: `${item.name} ${
					item.subcontractor?.name ? `(${item.subcontractor.name})` : ""
				}`,
			})),
		[trailers],
	);

	const renderedDrivers = useMemo(
		() =>
			drivers
				.map((item) => ({
					id: item.id,
					name: `${item.fullName} ${
						item.subcontractor?.name ? `(${item.subcontractor.name})` : ""
					}`,
					truck: item.truck || null,
					trailer: item.trailer || null,
				}))
				.filter((item) => item.id !== -1),
		[drivers],
	);

	const renderedSubcontractors = useMemo(
		() =>
			subcontractors
				.map((item) => ({
					id: item.id,
					name: item.name,
				}))
				.filter((item) => item.id !== -1),
		[subcontractors],
	);

	const renderedBusinessUnits = useMemo(
		() =>
			businessUnits
				.map((item) => ({
					id: item.id,
					name: item.name,
				}))
				.filter(
					(item) => item.id !== -1 && item.id !== currentBusinessUnit?.id,
				),
		[businessUnits, currentBusinessUnit],
	);

	const handleSearchDriversChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["fullName"],
		reduxAction: fetchDriversAction,
	});

	const handleSearchTrucksChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name", "registration"],
		reduxAction: fetchTrucksAction,
	});

	const handleSearchTrailersChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name"],
		reduxAction: fetchTrailersAction,
	});

	const handleSearchSubcontractorChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name"],
		reduxAction: fetchSubcontractorsAction,
	});

	const updateFields = (
		event: MultiSelectChangeEvent,
		fieldsToUpdate: { name: string; value: unknown }[],
	) => {
		for (const field of fieldsToUpdate) {
			formRenderProps.onChange(field.name, { value: field.value });
			handleInputChange(event, field.name, setFormData, field.value);
		}
	};

	const onGoodsDropdownChange = (event: MultiSelectChangeEvent) => {
		const selectedGoods = event.target.value;

		setGoodsIds(selectedGoods.map((item: IBase) => item.id));
	};

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchTrailersAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch trailers.",
		});
		dispatchWithNotifications({
			action: fetchDriversAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch drivers.",
		});
		dispatchWithNotifications({
			action: fetchTrucksAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch trucks.",
		});
		dispatchWithNotifications({
			action: fetchSubcontractorsAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch subcontractors.",
		});
	}, [dispatchWithNotifications]);

	return (
		<>
			<div>
				<Field
					id="goods"
					label="Goods"
					name="goods"
					dataItemKey="id"
					textField="name"
					data={goods}
					onChange={onGoodsDropdownChange}
					component={CelerumFormMultiSelect}
					validator={requiredValidator}
					required
				/>
				<Field
					id="ucr"
					name="ucr"
					label="UCR"
					component={CelerumFormInput}
					type="text"
					focused="true"
				/>
				<Field
					id="mrn"
					name="mrn"
					label="MRN"
					component={CelerumFormInput}
					type="text"
				/>
				<Field
					id="t1"
					name="t1"
					label="T1"
					component={CelerumFormInput}
					type="text"
				/>
				<Field
					id="clearanceLocation"
					name="clearanceLocation"
					label="Clearance Location"
					component={CelerumFormInput}
					type="text"
				/>
				<Field
					id="clearanceDate"
					name="clearanceDate"
					label="Clearance Date"
					component={CelerumFormDateTimePicker}
				/>
			</div>
			<div>
				<div className={styles.chaufferContainer}>
					<label className={styles.checkmark}>
						Assign
						<input
							type="radio"
							checked={selectedChauffeur === DRIVERS_FEATURE_KEY}
							name="chauffeur"
							onChange={() => {
								setSelectedChauffeur(DRIVERS_FEATURE_KEY);
							}}
						/>
						<span className={styles.checkmark__radio} />
					</label>
					<label className={styles.checkmark} style={{ color: "#9D9DAB" }}>
						Transfer
						<input
							type="radio"
							checked={selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY}
							name="chauffeur"
							disabled={true} // NOTE: this is disabled by intention
							onChange={() => {
								setSelectedChauffeur(BUSINESS_UNITS_FEATURE_KEY);
								setFormData((prevFormData) => {
									if (!prevFormData) return;
									return {
										...prevFormData,
										driver: null,
										truck: null,
										trailer: null,
										subcontractor: null,
									};
								});

								formRenderProps.onChange("driver", { value: null });
								formRenderProps.onChange("truck", { value: null });
								formRenderProps.onChange("trailer", { value: null });
								formRenderProps.onChange("subcontractor", { value: null });
							}}
						/>
						<span className={styles.checkmark__radio} />
					</label>
				</div>
				{selectedChauffeur === DRIVERS_FEATURE_KEY && (
					<>
						<Field
							id="driver"
							dataItemKey="id"
							label="Driver"
							textField="name"
							name="driver"
							handleSearchChange={handleSearchDriversChange}
							component={CelerumSuggestionsInput}
							data={renderedDrivers}
							onChange={async (event) => {
								const { value: driver } = event.target;
								setFormData((prevFormData) => {
									if (!prevFormData) return;
									return { ...prevFormData, driver: driver };
								});

								if (!driver) return;

								const result = (
									await jobApi.fleet.fleetFindList({ DriverId: driver.id })
								)?.data;

								if (formData?.truck?.id == null && result?.truck) {
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return { ...prevFormData, truck: result.truck };
									});
									formRenderProps.onChange("truck", { value: result.truck });
								}

								if (formData?.trailer?.id == null && result?.trailer) {
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return { ...prevFormData, trailer: result.trailer };
									});
									formRenderProps.onChange("trailer", {
										value: result.trailer,
									});
								}
							}}
						/>
						<Field
							id="truck"
							dataItemKey="id"
							label="Truck"
							textField="name"
							name="truck"
							handleSearchChange={handleSearchTrucksChange}
							component={CelerumSuggestionsInput}
							data={renderedTrucks}
							onChange={async (event) => {
								const { value: truck } = event.target;
								setFormData((prevFormData) => {
									if (!prevFormData) return;
									return {
										...prevFormData,
										truck: truck,
									};
								});
								if (!truck) return;

								const result = (
									await jobApi.fleet.fleetFindList({ TruckId: truck.id })
								)?.data;

								if (formData?.driver?.id == null && result.driver) {
									const driver = {
										...result.driver,
										name: `${result.driver.firstName} ${result.driver.lastName}`,
									} as IDriver;
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return {
											...prevFormData,
											driver: driver,
										};
									});
									formRenderProps.onChange("driver", { value: driver });
								}

								if (formData?.trailer?.id == null && result.trailer) {
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return {
											...prevFormData,
											trailer: result.trailer,
										};
									});
									formRenderProps.onChange("trailer", {
										value: result.trailer,
									});
								}

								if (
									formData?.subcontractor?.id == null &&
									result.subcontractor
								) {
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return {
											...prevFormData,
											subcontractor: result.subcontractor,
										};
									});

									const fieldsToUpdate = [
										{ name: "subcontractor", value: result.subcontractor },
										{ name: "supplierInvoice", value: SUPPLIER_INVOICES[1] },
									];

									updateFields(event, fieldsToUpdate);
								}
							}}
						/>
						<Field
							id="trailer"
							dataItemKey="id"
							label="Trailer"
							textField="name"
							name="trailer"
							handleSearchChange={handleSearchTrailersChange}
							component={CelerumSuggestionsInput}
							data={renderedTrailers}
							onChange={async (event) => {
								const { value: trailer } = event.target;
								setFormData((prevFormData) => {
									if (!prevFormData) return;
									return {
										...prevFormData,
										trailer: trailer,
									};
								});
								if (!trailer) return;

								const result = (
									await jobApi.fleet.fleetFindList({ TrailerId: trailer.id })
								)?.data;

								if (formData?.driver?.id == null && result.driver) {
									const driver = {
										...result.driver,
										name: `${result.driver.firstName} ${result.driver.lastName}`,
									} as IDriver;

									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return {
											...prevFormData,
											driver: driver,
										};
									});
									formRenderProps.onChange("driver", { value: driver });
								}

								if (formData?.truck?.id == null && result.truck) {
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return {
											...prevFormData,
											truck: result.truck,
										};
									});
									formRenderProps.onChange("truck", { value: result.truck });
								}
							}}
						/>
						<Field
							id="subcontractor"
							dataItemKey="name"
							label="Subcontractor"
							textField="name"
							name="subcontractor"
							handleSearchChange={handleSearchSubcontractorChange}
							component={CelerumSuggestionsInput}
							data={renderedSubcontractors}
							onChange={async (event) => {
								const { value: subcontractor } = event.target;
								setFormData((prevFormData) => {
									if (!prevFormData) return;
									return {
										...prevFormData,
										subcontractor: subcontractor,
									};
								});

								// NOTE: ignore if the values was set manually to other than the first two values
								if (
									formData?.supplierInvoice !== SUPPLIER_INVOICES[0] &&
									formData?.supplierInvoice !== SUPPLIER_INVOICES[1]
								) {
									return;
								}

								let valueSupplierInvoice = SUPPLIER_INVOICES[1];
								if (!subcontractor) {
									valueSupplierInvoice = SUPPLIER_INVOICES[0];
								}

								const fieldsToUpdate = [
									{ name: "supplierInvoice", value: valueSupplierInvoice },
								];

								updateFields(event, fieldsToUpdate);
							}}
						/>
					</>
				)}
				{selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY && (
					<Field
						id="businessUnit"
						dataItemKey="id"
						label="Business Unit"
						textField="name"
						name="businessUnit"
						component={CelerumFormDropDownList}
						data={renderedBusinessUnits}
						disabled={true} // NOTE: this is disabled by intention
						onChange={(event) =>
							handleInputChange(event, "businessUnit", setFormData)
						}
						validator={requiredValidator}
						required={selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY}
					/>
				)}
			</div>
			<div>
				<div className={styles.currency}>
					<div className={styles.currency__cost}>
						<Field
							id="cost"
							label="Cost"
							name="cost"
							component={CelerumFormInput}
							type="number"
							onChange={(event) => {
								const value = Number.parseFloat(event.target.value);
								setCost(Number.isNaN(value) ? undefined : value);
							}}
							validator={costValidator}
						/>
					</div>
					<div className={styles.currency__code}>
						<Field
							id="currency"
							dataItemKey="id"
							label="Currency"
							textField="code"
							name="currency"
							component={CelerumFormDropDownList}
							data={currencies}
							validator={currencyValidator}
							required={cost !== undefined ? cost >= 0 : false}
						/>
					</div>
				</div>
				<Field
					id="supplierInvoiceNumber"
					label="Supplier Invoice Number"
					name="supplierInvoiceNumber"
					maxLength={500}
					component={CelerumFormInput}
					onChange={(event) =>
						handleInputChange(event, "supplierInvoiceNumber", setFormData)
					}
				/>
				<Field
					id="supplierInvoice"
					label="Supplier Invoice"
					name="supplierInvoice"
					dataItemKey="id"
					textField="name"
					component={CelerumFormDropDownList}
					data={SUPPLIER_INVOICES}
					defaultValue={SUPPLIER_INVOICES[0]}
					onChange={(event) =>
						handleInputChange(event, "supplierInvoice", setFormData)
					}
				/>
				<Field
					id="supplierInvoiceDate"
					label="Supplier Invoice Date"
					name="supplierInvoiceDate"
					component={CelerumFormDatePicker}
					onChange={(event) =>
						handleInputChange(event, "supplierInvoiceDate", setFormData)
					}
				/>
				<Field
					id="note"
					label="Notes"
					name="notes"
					maxLength={500}
					component={CelerumFormTextArea}
					type="text"
				/>
				{!updateMode && (
					<Field
						label="Upload Documents"
						id="files"
						name="files"
						component={CelerumFormUploadInput}
					/>
				)}
			</div>
		</>
	);
};
